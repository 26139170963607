import React, { useState } from "react";
import { FaUser, FaLock, FaMobileAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import {
  validatePassword,
  validateEmail,
  validateMobile,
  validateName,
} from "../utils/validations";
import { encryptText } from "../utils/AESencryption";
import { signupUser } from "../api/api";
import SignupVerificationPopup from "../components/SignUpPop";

const Signup = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    password: '',
    confirmPassword: '',
    termsAccepted: false,
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const validateForm = () => {
    let newErrors = {};

    const validatingName = validateName(formData.fullname);
    if (validatingName) newErrors.fullname = validatingName;

    const validatingEmail = validateEmail(formData.email);
    if (validatingEmail) newErrors.email = validatingEmail;

    const validatingMobile = validateMobile(formData.mobile);
    if (validatingMobile) newErrors.mobile = validatingMobile;

    // const validatingPassword = validatePassword(formData.password);
    // if (validatingPassword) newErrors.password = validatingPassword;

    // if (formData.password !== formData.confirmPassword) {
    //   newErrors.confirmPassword = 'Passwords do not match';
    // }

    if (!formData.termsAccepted) {
      newErrors.termsAccepted = "You must accept the terms and conditions";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const EncryptedEmail = encryptText(formData.email);
      const EncryptedContact = encryptText(formData.mobile);
      const EncryptedPassword = encryptText("demo@ims");

      const newUser = {
        name: formData.fullname.trim(),
        email: EncryptedEmail.trim(),
        roleId: 1,
        contact: EncryptedContact.trim(),
        password: EncryptedPassword.trim(),
      };

      try {
        const response = await signupUser(newUser);
        // 

        // let response = {
        //   status: 200,
        // };

        if (response.status === 200) {
          toast.success(
            "User created successfully. Please check your email for verification."
          );
          setFormData({
            fullname: "",
            email: "",
            mobile: "",
            password: '',
            confirmPassword: '',
            termsAccepted: false,
          });
        }
        
        setIsPopupVisible(true);
        
      } catch (error) {
        console.error("Error creating user:", error);
      }
    }
  };

  const isButtonDisabled =
    !formData.fullname ||
    !formData.email ||
    !formData.mobile ||
    !formData.password ||
    !formData.confirmPassword ||
    !formData.termsAccepted;

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl w-full border border-gray-200">
        <img src={imsLogo} alt="Logo" className="w-32 mx-auto mb-4" />
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">
          Signup
        </h2>

        <form>
          {/* Fullname Field */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-medium mb-1"
              htmlFor="fullname"
            >
              Fullname
            </label>
            <div className="relative">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                <FaUser />
              </span>
              <input
                type="text"
                id="fullname"
                value={formData.fullname}
                onChange={(e) => {
                  // if (validateName(formData.fullname)) 
                  setErrors((prev) => ({
                    ...prev,
                    fullname: validateName(e.target.value),
                  }));
                  setFormData({ ...formData, fullname: e.target.value });
                  // setErrors((prev) => ({ ...prev, fullname: "" }));
                }}
                placeholder="Enter your fullname"
                required
                className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.fullname ? "border-red-500" : "border-gray-300"
                }`}
              />
            </div>
            {errors.fullname && (
              <p className="text-red-500 text-xs mt-1">{errors.fullname}</p>
            )}
          </div>

          {/* Email and Mobile Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="email"
              >
                Email
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  <FaUser />
                </span>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={(e) => {
                    setErrors((prev) => ({
                      ...prev,
                      email: validateEmail(e.target.value),
                    }));
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  placeholder="Enter your email"
                  required
                  className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  }`}
                />
              </div>
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="mobile"
              >
                Mobile Number
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  <FaMobileAlt />
                </span>
                <input
                  type="tel"
                  id="mobile"
                  value={formData.mobile}
                  onChange={(e) => {
                    setErrors((prev) => ({ ...prev, mobile: validateMobile(e.target.value) }));
                    setFormData({ ...formData, mobile: e.target.value });
                  }}
                  placeholder="Enter your mobile number"
                  required
                  className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    errors.mobile ? "border-red-500" : "border-gray-300"
                  }`}
                />
              </div>
              {errors.mobile && (
                <p className="text-red-500 text-xs mt-1">{errors.mobile}</p>
              )}
            </div>
          </div>

          {/* Password and Confirm Password Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="password">
                Password
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  <FaLock />
                </span>
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    setErrors((prev) => ({ ...prev, password: '' }));
                  }}
                  placeholder="Enter your password"
                  required
                  className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
                />
                <span
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="confirmPassword">
                Confirm Password
              </label>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                  <FaLock />
                </span>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) => {
                    setFormData({ ...formData, confirmPassword: e.target.value });
                    setErrors((prev) => ({ ...prev, confirmPassword: '' }));
                  }}
                  placeholder="Confirm your password"
                  required
                  className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`}
                />
                <span
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>}
            </div>
          </div> 

          {/* Terms and Conditions Checkbox */}
          <div className="flex items-center mb-4">
            <Checkbox
              checked={formData.termsAccepted}
              onChange={(e) =>
                setFormData({ ...formData, termsAccepted: e.target.checked })
              }
            />
            <span className="text-gray-700 text-sm">
              I accept the{" "}
              <a href="#" className="text-blue-500 underline">
                terms and conditions
              </a>
            </span>
          </div>
          {errors.termsAccepted && (
            <p className="text-red-500 text-xs mb-4">{errors.termsAccepted}</p>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isButtonDisabled}
            className={`bg-indigo-600 text-white py-2 px-4 rounded-lg w-full ${
              isButtonDisabled
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-indigo-600"
            }`}
            onClick={handleSubmit}
          >
            Signup
          </button>

          <div className="mt-4 text-center">
            <p className="text-sm text-gray-600">
              already have an account?{" "}
              <a href="/login" className="text-blue-600 hover:underline">
                Login here
              </a>
            </p>
          </div>
        </form>
      </div>
      {isPopupVisible && <SignupVerificationPopup />}
    </div>
  );
};

export default Signup;
