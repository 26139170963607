import { jwtDecode } from "jwt-decode";

export const getPermissionsFromToken = () => {
  const token = localStorage.getItem("token");
  if (!token) return [];
  const decodedToken = jwtDecode(token);
  return decodedToken.permissions || [];
};

export const getUserInfoFromToken = (newtoken) => {
  const token = sessionStorage.getItem("token");
  if (!newtoken && !token) return {};
  const decodedToken = jwtDecode(newtoken || token);
  return decodedToken || {};
};
