export const validateEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const domain = email.split("@")[1]; // Get the domain part of the email
  if (!email) return "Email is required.";
  if (email.length > 254) {
    return "Email exceeds the maximum length limit of 254 characters.";
  }
  if (/\.\./.test(email)) {
    return "Email cannot contain consecutive dots (..).";
  }
  if (domain) {
    const domainSegments = domain.split("."); // Split domain by dots to get each segment
    for (const segment of domainSegments) {
      if (segment.length >= 63) {
        return "Domain segment exceeds the maximum length of 63 characters.";
      }
    }
  }
  if (!regex.test(email)) return "Please enter a valid email address.";
  return null;
};

export const validateMobile = (mobile) => {
  const regex = /^[6-9]\d{9}$/;
  if (!mobile) return "Mobile number is required.";
  if (!regex.test(mobile))
    return "Mobile number must start with 6-9 and be 10 digits long.";
  return null;
};

export const validateName = (name) => {
  const regex = /[^a-zA-Z\s]/;
  const spaceRegex = /^\s|\s{2,}|\s$/;
  const consecutiveSpacesRegex = /\s{2,}/;

  if (!name || name.trim().length === 0) return "Name is required.";

  if (consecutiveSpacesRegex.test(name)) {
    return "Name should not contain consecutive spaces.";
  }

  if (regex.test(name)) {
    return "Name should only contain letters.";
  } else if (spaceRegex.test(name)) {
    return "Name should not have leading, trailing, or multiple spaces.";
  } else if (name.length > 50) {
    return "Name is too long.";
  } else if (name.length < 3) {
    return "Name is too short.";
  } else if (/<.*?>/.test(name) || /<script.*?>.*?<\/script>/i.test(name)) {
    return "Name should not contain HTML tags or JavaScript.";
  }
  return null;
};

export const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{6,8}$/;
  if (!password) return "Password is required.";
  if (!regex.test(password))
    return "Password must be 6 to 8 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.";
  return null;
};
