import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { getUserInfoFromToken } from "../utils/decryptToken";

const Header = ({ toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const user = getUserInfoFromToken();
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownItemClick = (action) => {
    switch (action) {
      case "Profile":
        navigate("/profile");
        break;
      case "Logout":
        localStorage.removeItem("token");
        navigate("/login");
        break;
      default:
        break;
    }
    setIsDropdownOpen(false);
  };

  function getInitials(fullName) {
    if (!fullName || typeof fullName !== "string") {
      return "";
    }

    const nameParts = fullName.trim().split(" ");

    const firstInitial = nameParts[0].charAt(0).toUpperCase();
    const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
  }

  return (
    <header className="bg-white shadow-md p-4 flex justify-between items-center">
      <button
        onClick={toggleSidebar}
        className="md:hidden p-2 text-indigo-600 rounded-md focus:outline-none transition"
      >
        <FaBars className="w-6 h-6" />
      </button>

      <div className="flex-grow" />

      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="bg-indigo-600 text-white rounded-full w-10 h-10 flex items-center justify-center focus:outline-none hover:bg-indigo-600 transition"
        >
          {getInitials(user.name)}
        </button>

        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white text-gray-800 border border-gray-300 rounded shadow-lg z-10">
            <ul>
              <li
                onClick={() => handleDropdownItemClick("Profile")}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                Profile
              </li>
              <li
                onClick={() => handleDropdownItemClick("Logout")}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
