import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';

const EditUniversity = ({ university, onClose, onUniversityUpdated }) => {
  const [universityName, setUniversityName] = useState(university.name);
  const [status, setStatus] = useState(university.isActive.data === 1 ? 'active' : 'inactive');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setUniversityName(university.name);
    setStatus(university.isActive.data == 1 ? 'active' : 'inactive');
  }, [university]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const updatedUniversity = {
        universityId: university.id,
        universityName: universityName,
        status: status == 'active' ? 1 : 0,
      };

      const response = await axiosPrivateInstance.put('/university/update', updatedUniversity);

      if (response) {
        
        onUniversityUpdated(response.data.data[0][0][0]);  
        onClose(); 
      }
    } catch (err) {
      console.error('Error updating university:', err);
      setError('An error occurred while updating the university.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg w-96">
        <h2 className="text-2xl font-semibold mb-4">Edit University</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">University Name:</label>
            <input
              type="text"
              value={universityName}
              onChange={(e) => setUniversityName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md mt-2"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Status:</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md mt-2"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          {error && <div className="text-red-500 text-sm mb-4">{error}</div>}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 ${loading ? 'bg-gray-400' : 'bg-indigo-500'} text-white rounded-md hover:bg-indigo-600`}
            >
              {loading ? 'Updating...' : 'Update University'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUniversity;
