import React, { useState } from 'react';
import axios from 'axios'; 
import axiosPrivateInstance from '../../api/axiosPrivateInstance';

const AddUniversity = ({ onClose, onUniversityAdded }) => {
  const [universityName, setUniversityName] = useState('');
  const [status, setStatus] = useState('active');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError(''); 

    try {
      const universityData = { name: universityName, status };

      

      const response = await axiosPrivateInstance.post('/university/create', universityData);

      

      if (response) {
        const university = response.data.data[0][0]; 


        onUniversityAdded(university);
        onClose();
      } else {
        
        setError('An unexpected error occurred.');
      }
    } catch (err) {
      console.error('Error adding university:', err);
      setError(`An error occurred while adding the university: ${err.message}`);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg w-96">
        <h2 className="text-2xl font-semibold mb-4">Add New University</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">University Name:</label>
            <input
              type="text"
              value={universityName}
              onChange={(e) => setUniversityName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md mt-2"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Status:</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md mt-2"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          {error && <div className="text-red-500 text-sm mb-4">{error}</div>} {/* Error message display */}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className={`px-4 py-2 ${loading ? 'bg-gray-400' : 'bg-indigo-500'} text-white rounded-md hover:bg-indigo-600`}
            >
              {loading ? 'Adding...' : 'Add University'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUniversity;
