import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaUserEdit, FaCalendar, FaUniversity, FaGraduationCap, FaUserGraduate, FaSignOutAlt, FaChevronDown, FaChevronUp } from "react-icons/fa";
import gusLogo from "../assets/logo.jpg";
import { fetchPermissions } from "../api/api";
import { setMenuItems, setLoading, setError } from "../utils/menuSlice";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { menuItems, loading, error } = useSelector((state) => state.menu);
  const [openDropdowns, setOpenDropdowns] = useState({});

  const isActive = (path) => location.pathname.startsWith(path);

  useEffect(() => {
    const getPermissionsAndSetMenu = async () => {
      if (menuItems.length > 0) return;

      try {
        dispatch(setLoading(true)); 

        const permissions = await fetchPermissions();

        if (permissions.error) {
          dispatch(setError("Failed to load permissions."));
          return;
        }

        const roleSpecificItems = [
          {
            path: "/user",
            label: "User",
            icon: <FaUserEdit />,
            permission: "get_user",
          },
          {
            path: "/universities",
            label: "University",
            icon: <FaUniversity />,
            permission: "get_university",
          },
          {
            label: "Students",
            icon: <FaGraduationCap />,
            subItems: [
              {
                path: "/csv",
                label: "Enroll Students",
                icon: <FaUserGraduate />,
                permission: "upload_csv_file",
              },
              {
                path: "/student",
                label: "Student List",
                icon: <FaGraduationCap />,
                permission: "student_list",
              },
            ],
          },
          {
            path: "/booking",
            label: "Appointments",
            icon: <FaCalendar />,
            permission: "get_appointment",
          },
        ];

        const filteredMenuItems = roleSpecificItems
          .map((item) => {
            const filteredSubItems = item.subItems?.filter((subItem) =>
              permissions.some((perm) => perm.permission === subItem.permission)
            );

            return {
              ...item,
              subItems: filteredSubItems,
            };
          })
          .filter(
            (item) =>
              permissions.some((perm) => perm.permission === item.permission) ||
              (item.subItems && item.subItems.length > 0)
          );

        dispatch(setMenuItems(filteredMenuItems));
      } catch {
        dispatch(setError("An error occurred while fetching permissions."));
      } finally {
        dispatch(setLoading(false));
      }
    };

    getPermissionsAndSetMenu();
  }, [menuItems,dispatch]);

  const toggleDropdown = (label) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [label]: !prevState[label],
    }));
  };

  const handleLogout = () => {
    localStorage.removeItem("menuItems"); 
    dispatch(setMenuItems([]));
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm z-40 transition-opacity duration-300"
          onClick={toggleSidebar}
        />
      )}
      <div
        className={`fixed inset-y-0 left-0 w-64 h-full flex flex-col shadow-xl transform transition-transform duration-300 ease-in-out z-50 
        bg-gradient-to-b from-gray-700 to-gray-900 border border-gray-700/50 ${isOpen ? "translate-x-0" : "-translate-x-full"} md:relative md:translate-x-0`}
      >
        <div className="flex justify-between items-center p-4 border-b border-gray-700/50">
          <img
            src={gusLogo}
            alt="GUS Logo"
            className="w-20 h-auto"
            onError={(e) => (e.target.style.display = "none")}
          />
          {isOpen && (
            <button
              onClick={toggleSidebar}
              className="text-gray-400 hover:text-white focus:outline-none transition duration-200"
            >
              <FaChevronUp className="w-6 h-6" />
            </button>
          )}
        </div>

        <div className="flex-grow overflow-y-auto custom-scrollbar px-2 mt-1 ">
          {loading ? (
            <p className="text-center text-gray-400 p-4">Loading menu...</p>
          ) : error ? (
            <p className="text-center text-red-400 p-4">{error}</p>
          ) : menuItems.length === 0 ? (
            <p className="text-center text-gray-400 p-4">No items available to display.</p>
          ) : (
            <nav>
              <ul>
                {menuItems.map(({ label, icon, subItems, path }) =>
                  subItems ? (
                    <li key={label} className="mb-2 ">
                      <button
                        onClick={() => toggleDropdown(label)}
                        className="flex items-center justify-between w-full p-2 rounded-lg transition-all duration-300 hover:bg-white/10 text-gray-300"
                      >
                        <div className="flex items-center">
                          <span className="text-sm mr-3">{icon}</span>
                          <span className="text-md">{label}</span>
                        </div>
                        <span className="text-gray-300">
                          {openDropdowns[label] ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                      </button>
                      {openDropdowns[label] && (
                        <ul className="ml-4 mt-2 space-y-2">
                          {subItems.map(({ path, label, icon }) => (
                            <li key={path}>
                              <Link
                                to={path}
                                className={`flex items-center p-2 rounded-lg transition-all duration-300 ${
                                  isActive(path)
                                    ? "bg-indigo-500 text-white shadow-lg"
                                    : "hover:bg-gray-700 text-gray-300"
                                }`}
                              >
                                <span className="text-sm mr-3">{icon}</span>
                                <span className="text-md">{label}</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li key={path} className="mb-2">
                      <Link
                        to={path}
                        className={`flex items-center p-2 rounded-lg transition-all duration-300 ${
                          isActive(path)
                            ? "bg-indigo-500 text-white shadow-lg"
                            : "hover:bg-gray-700 text-gray-300"
                        }`}
                      >
                        <span className="text-sm mr-3">{icon}</span>
                        <span className="text-md">{label}</span>
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </nav>
          )}
        </div>

        <div className="border-t border-gray-700/50 mt-4 p-2">
          <button
            onClick={handleLogout}
            className="w-full flex items-center p-3 rounded-lg transition-all duration-300 hover:bg-red-600 text-white"
          >
            <FaSignOutAlt className="mr-3 text-lg" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
