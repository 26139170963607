import React, { useState, useEffect, useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Papa from "papaparse";
import axios from "axios";
import DataTable from "../../components/DataTable";
import { getUserInfoFromToken } from "../../utils/decryptToken";
import axiosPrivateInstance from "../../api/axiosPrivateInstance";

registerPlugin(FilePondPluginFileValidateType);

const CsvUploader = () => {
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [universities, setUniversities] = useState([]);
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  
  const filePondRef = useRef(null);  

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const newtoken = getUserInfoFromToken(token);
      if (newtoken.roleId === 2) {
        setSelectedUniversity(newtoken.universityId);
        setIsDropdownDisabled(true);
      }
    }

    const fetchUniversities = async () => {
      try {
        const response = await axiosPrivateInstance.get("/university/get");
        setUniversities(response.data);
      } catch (error) {
        setError("Error fetching universities");
      }
    };

    fetchUniversities();
  }, []);

  const parseCsvFile = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const formattedData = results.data.map((row) => ({
          matriculationNumber: row.matriculationNumber,
          name: row.name,
          email: row.email,
          phoneNumber: row.phoneNumber,
          dateOfBirth: row.dateOfBirth,
          serviceName: row.serviceName,
          universityId: selectedUniversity,
        }));
        setCsvData(formattedData);
        setError(null);
        setSuccess("CSV file parsed successfully! Review data below.");
      },
      error: () => {
        setError("Error parsing CSV file");
        setSuccess(null);
      },
    });
  };

  const handleUniversityChange = (event) => {
    const newUniversityId = event.target.value;
    setSelectedUniversity(newUniversityId);

    const remappedData = csvData.map((row) => ({
      ...row,
      universityId: newUniversityId,
    }));
    setCsvData(remappedData);
  };

  const handleSubmit = async () => {
    if (!selectedUniversity) {
      setError("Please select a university");
      return;
    }

    if (csvData.length === 0) {
      setError("No data to upload");
      return;
    }

    try {
      const response = await axiosPrivateInstance.post("/student/create", {
        studentData: csvData,
      });

      if (response.status === 200) {
        alert("Data uploaded successfully!");
        setCsvData([]);
        setSuccess("Data uploaded successfully!");
        setError(null);
        if (filePondRef.current) {
          filePondRef.current.removeFiles();
        }
      } else {
        setError("Failed to upload data");
        setSuccess(null);
      }
    } catch (error) {
      setError("An error occurred while uploading data");
      setSuccess(null);
    }
  };

  return (
    <div className="bg-white text-gray-900 min-h-screen p-8">
      <div className="bg-gray-50 p-6 rounded-lg shadow-md w-full mx-auto">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-2 rounded mb-2">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-2 py-2 rounded mb-2">
            {success}
          </div>
        )}

        {/* University Selection */}
        {!isDropdownDisabled && (
          <div className="mb-2">
            <label
              htmlFor="universitySelect"
              className="block text-gray-700 font-medium mb-2"
            >
              Select University
            </label>
            <select
              id="universitySelect"
              value={selectedUniversity}
              onChange={handleUniversityChange}
              className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">-- Select University --</option>
              {universities.map((uni) => (
                <option key={uni.id} value={uni.id}>
                  {uni.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* FilePond Integration */}
        <div className="mb-2">
          <label className="block text-gray-700 font-medium mb-2">
            Upload CSV File
          </label>
          <FilePond
            ref={filePondRef}
            allowMultiple={false}
            acceptedFileTypes={["text/csv"]}
            onaddfile={(err, file) => {
              if (err) {
                setError("Error uploading file");
                setSuccess(null);
              } else {
                parseCsvFile(file.file);
              }
            }}
            onremovefile={() => {
              setCsvData([]);
              setError(null);
              setSuccess(null);
            }}
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          className="w-1/5 bg-indigo-500 text-white py-3 rounded-lg shadow-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          Upload Students
        </button>
      </div>

      <div className="mt-6">
        <h2 className="text-xl font-semibold text-gray-900">Preview Data</h2>
        {csvData.length > 0 ? (
          <DataTable
            headers={["Matriculation Number", "Name", "Email", "Phone Number", "Date of Birth", "Service Name"]}
            data={csvData}
            fieldKeys={["matriculationNumber", "name", "email", "phoneNumber", "dateOfBirth", "serviceName"]}
          />
        ) : (
          <p className="text-center text-gray-500 mt-4">No data uploaded</p>
        )}
      </div>
    </div>
  );
};

export default CsvUploader;
