import axios from 'axios';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';

export const fetchUniversities = async () => {

  try {
    const response = await axiosPrivateInstance.get(
      "/university/get/all"
    );
    if (response.data && response.data.length > 0) {
      return response.data;
    } 
  } catch (error) {
    console.error("Error fetching universities", error);
  }
};

export const addUniversity = async (universityData) => {
  try {
    const response = await axiosPrivateInstance.post("/university/create-universities"
    , universityData);
    return response.data;
  } catch (error) {
    console.error("Error adding university", error);
    throw error;
  }
};
