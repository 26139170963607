import axiosPrivateInstance from "./axiosPrivateInstance";
import { toast } from "react-toastify";



export const fetchPermissions = async () => {
  try {
    const response = await axiosPrivateInstance.get("/user-permissions");

    if (response.status === 200) {
      return response.data; // Return the permissions data
    }
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        toast.error("Unauthorized access");
        return { error: "Unauthorized access" };
      } else if (status === 403) {
        toast.error("Forbidden: You do not have permission to access this resource.");
        return { error: "Forbidden" };
      } else {
        toast.error("Something went wrong while fetching permissions.");
        return { error: "Something went wrong" };
      }
    } else if (error.request) {
      toast.error("Network error, please check your connection.");
      return { error: "Network error" };
    } else {
      toast.error("An unexpected error occurred, please try again.");
      return { error: "Unexpected error" };
    }
  }
};


export const loginUser = async (emailorMobile, password) => {
  try {
    const response = await axiosPrivateInstance.post("/auth/login", {
      emailorMobile,
      password,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        toast.error("Invalid credentials");
        return { error: "Invalid credentials" };
      } else if (status === 400) {
        toast.error(error.response.data.message || "Invalid input.");
        return { error: error.response.data.message || "Invalid input" };
      } else {
        toast.error("Something went wrong, please try again later.");
        return { error: "Something went wrong" };
      }
    } else if (error.request) {
      toast.error("Network error, please check your connection.");
      return { error: "Network error" };
    } else {
      toast.error("An unexpected error occurred, please try again.");
      return { error: "Unexpected error" };
    }
  }
};

export const signupUser = async (userDetails) => {
  try {
    const response = await axiosPrivateInstance.post(
      "/auth/signup",
      userDetails
    );

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 400) {
        toast.error(
          "Failed to add user as email or contact number already exits"
        );
      } else if (status === 403) {
        toast.error("Unauthorized Access");
      } else {
        toast.error("User already exits in this branch.");
      }
    } else {
      console.error("Error creating user:", error);
      toast.error("Something went wrong, please try again");
    }
  }
};

export const resetPassword = async (newPassword, token) => {
  

  try {
    const response = await axiosPrivateInstance.put("/auth/reset-password", {
      newPassword,
      token,
    });

    if (response.status === 200) {
      toast.success("Password reset successful!");
      return response.data;
    } else {
      toast.error("Password reset failed. Please try again.");
    }
  } catch (error) {
    

    toast.error(
      error.response?.data?.message || "Something went wrong. Please try again."
    );
  }
};

export const setNewPassword = async (password, userId) => {
  

  try {
    const response = await axiosPrivateInstance.put("/auth/set-password", {
      password,
      userId,
    });

    if (response.status === 200) {
      toast.success("Password set successful!");
      return response;
    } else {
      toast.error("Password set failed. Please try again.");
    }
  } catch (error) {
    

    toast.error(
      error.response?.data?.message || "Something went wrong. Please try again."
    );
  }
};

export const sendResetPasswordLink = async (email) => {
  try {
    const response = await axiosPrivateInstance.post("/auth/forgot-password", {
      email,
    });

    if (response.status === 200) {
      toast.success(
        "Password reset link sent successfully. Please check your email."
      );
      return response.data;
    } else if (response.status === 404) {
      return toast.error("Email not found. Please provide a valid email.");
    } else if (response.status === 400) {
      return toast.error("Invalid email format.");
    }
  } catch (error) {
    return toast.error("Something went wrong. Please try again later.");
  }
};

export const logout = (navigate) => {
  localStorage.removeItem("token");
  navigate("/login");
};

export const getActiveRoles = async () => {
  try {
    const response = await axiosPrivateInstance.get("/role/active/get");
    

    if (response.status === 200) {
      return response.data;
    } else if (response.status == 404) {
      return toast.error("Role Not Found");
    } else if (response.status == 403) {
      return toast.error("Unauthorized Access");
    }
  } catch (error) {
    return toast.error("Something went wrong, please try again");
  }
};

export const getUser = async () => {
  try {
    const response = await axiosPrivateInstance.get("/users/all");
    

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 404) {
      toast.error("Module Not Found");
    } else if (response.status === 403) {
      toast.error("Unauthorized Access");
    } else {
      toast.error("Unexpected response from the server");
    }
  } catch (error) {
    console.error("Error fetching users:", error);
    toast.error("Something went wrong, please try again");
  }
};

export const createUser = async (userDetails) => {
  try {
    const response = await axiosPrivateInstance.post(
      "/users/create",
      userDetails
    );

    if (response.status === 201) {
      toast.success("User added Successfully");
      return response.data;
    }
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 400) {
        toast.error(
          "Failed to add user as email or contact number already exits"
        );
      } else if (status === 403) {
        toast.error("Unauthorized Access");
      } else {
        toast.error("User already exits in this branch.");
      }
    } else {
      console.error("Error creating user:", error);
      toast.error("Something went wrong, please try again");
    }
  }
};

export const updateUserStatus = async (userId, status) => {
  try {
    const response = await axiosPrivateInstance.put("/users/status/update", {
      id: userId,
      status: status,
    });

    if (response.status === 201) {
      return response.data;
    } else if (response.status === 400) {
      return toast.error("All mandatory fields are required");
    } else if (response.status === 403) {
      return toast.error("Unauthorized Access");
    } else if (response.status === 404) {
      return toast.error("Module Not Found");
    }
  } catch (error) {
    console.error("Error editing user:", error);
    return toast.error("Something went wrong, please try again");
  }
};

export const editUser = async (userDetails) => {
  try {
    const response = await axiosPrivateInstance.put(
      "/users/update",
      userDetails
    );

    

    if (response.status === 200) {
      toast.success("User updated sucessfully.");
      return response.data;
    }

    if (response.status === 400) {
      toast.error("All mandatory fields are required");
    } else if (response.status === 403) {
      toast.error("Unauthorized Access");
    } else if (response.status === 404) {
      toast.error("User Not Found");
    }
  } catch (error) {
    

    if (error.response) {
      console.error("Server responded with an error:", error.response.data);
      toast.error("User already exits in this branch.");
    } else if (error.request) {
      console.error("No response received:", error.request);
      toast.error("No response from the server. Please try again.");
    } else {
      console.error("Error setting up the request:", error.message);
      toast.error("Request setup error. Please try again.");
    }
  }
};

