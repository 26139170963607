import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import DataTable from "../../components/DataTable";
import { getUserInfoFromToken } from "../../utils/decryptToken";
import axiosPrivateInstance from "../../api/axiosPrivateInstance";

const BookingDetails = () => {
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusDropdownVisible, setStatusDropdownVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentBookingDetails, setCurrentBookingDetails] = useState({
    bookingId: null,
    itemId: null,
    serviceId: null,
  });
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const formatDate = (date) => {
    return moment(date).utc().format("MMMM DD, YYYY, hh:mm A");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const newtoken = getUserInfoFromToken(token);
      if (newtoken.roleId === 2) {
        setSelectedUniversity(newtoken.universityId);
        setIsDropdownDisabled(true);
      }
    }

    const fetchUniversities = async () => {
      try {
        const response = await axiosPrivateInstance.get("/university/get");
        setUniversities(response.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  useEffect(() => {
    if (!selectedUniversity) return;

    const fetchBookingDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivateInstance.post(
          "/booking/get/all",
          {
            universityId: selectedUniversity,
          }
        );
        setBookingData(response.data);
      } catch (error) {
        console.error("Error fetching booking details:", error);
        alert("Failed to fetch booking details. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookingDetails();
  }, [selectedUniversity]);

  const headers = useMemo(
    () => [
      "Matriculation Number",
      "Student Name",
      "Student Email",
      "Booking Date/Time",
      "Staff Assigned",
      "Service Name",
      "Booking Status",
      "Appointment Status",
    ],
    []
  );

  const fieldKeys = useMemo(
    () => [
      "matriculationNumber",
      "studentName",
      "studentEmail",
      "bookingDateTime",
      "staffAssigned",
      "serviceName",
      "bookingStatus",
      "appointmentStatus",
    ],
    []
  );

  const handleEditAppointmentStatus = async (bookingId, newStatus, serviceId, itemId) => {
    try {
      await axiosPrivateInstance.put(
        `/booking/update/appointmentstatus`,
        {
          bookingId,
          appointmentStatus: newStatus,
          serviceId,
          itemId,
        }
      );
      setBookingData((bookingData) =>
        bookingData.map((booking) =>
          booking.bookingId === bookingId
            ? { ...booking, appointmentStatus: newStatus }
            : booking
        )
      );
      alert("Appointment status updated successfully.");
      setStatusDropdownVisible(false);
    } catch (error) {
      console.error("Error updating appointment status:", error);
      alert("Failed to update appointment status. Please try again.");
    }
  };

  const handleEditClick = (row) => {
    setCurrentBookingDetails({
      bookingId: row.bookingId,
      itemId: row.itemId,
      serviceId: row.serviceId,
    });
    setSelectedStatus(""); // Reset the selected status
    setStatusDropdownVisible(true); // Show the dropdown
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSaveStatus = () => {
    if (!selectedStatus) {
      alert("Please select a status.");
      return;
    }

    const { bookingId, itemId, serviceId } = currentBookingDetails; // Extract all details
    handleEditAppointmentStatus(bookingId, selectedStatus, serviceId, itemId); // Pass them to the handler
  };

  const actions = [
    {
      icon: <FaEdit className="text-indigo-600 text-xl" />,
      handler: handleEditClick, // Automatically passes the row
    },
  ];

  const renderUniversitySelector = () => (
    <div className="mb-6">
      <label htmlFor="universitySelect" className="text-xl font-semibold text-gray-700">
        Select University:
      </label>
      <select
        id="universitySelect"
        value={selectedUniversity}
        onChange={(e) => setSelectedUniversity(e.target.value)}
        className="ml-4 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
        disabled={isDropdownDisabled}
      >
        <option value="">-- Select --</option>
        {universities.map((university) => (
          <option key={university.id} value={university.id}>
            {university.name}
          </option>
        ))}
      </select>
    </div>
  );

  const renderLoader = () => (
    <div className="flex justify-center items-center h-64">
      <div className="loader"></div>
    </div>
  );

  const renderNoDataMessage = () => (
    <div className="text-center text-xl text-gray-600 mt-8">
      No bookings found.
    </div>
  );

  const renderDataTable = () => {
    const formattedBookingData = bookingData.map((booking) => ({
      ...booking,
      bookingDateTime: formatDate(booking.bookingDateTime),
    }));

    return (
      <DataTable
        headers={headers}
        data={formattedBookingData}
        fieldKeys={fieldKeys}
        actions={actions}
      />
    );
  };

  const renderStatusDropdown = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold text-gray-700 mb-4">
          Update Appointment Status
        </h3>
        <select
          value={selectedStatus}
          onChange={handleStatusChange}
          className="p-2 border border-gray-300 rounded mb-4 w-full"
        >
          <option value="">Select Status</option>
          <option value="Completed">Completed</option>
          <option value="NoShow">NoShow</option>
        </select>
        <div className="w-full flex justify-center space-x-4">
          <button
            onClick={() => setStatusDropdownVisible(false)}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveStatus}
            className="px-4 py-2 bg-indigo-600 text-white rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto p-6 bg-white shadow-xl rounded-lg relative">
      <h1 className="text-2xl font-semibold text-gray-800 mb-6">Appointments</h1>
      {!isDropdownDisabled && renderUniversitySelector()}
      {isLoading
        ? renderLoader()
        : bookingData.length === 0
        ? renderNoDataMessage()
        : renderDataTable()}
      {statusDropdownVisible && renderStatusDropdown()}
    </div>
  );
};

export default BookingDetails;
