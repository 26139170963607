import React, { useState } from 'react';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { validatePassword } from '../utils/validations';
import { toast } from 'react-toastify';
import { resetPassword, setNewPassword } from '../api/api';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const setPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
   
     
    const passwordError = validatePassword(password);
    if (passwordError) {
      newErrors.password = passwordError;
    }

     
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

     
    if (Object.keys(newErrors).length === 0) {
      const response = await setNewPassword(password,userId);
      if(response.status === 200){
        navigate("/login");
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full border border-gray-200">
        <img src={imsLogo} alt="Logo" className="w-32 mx-auto mb-4" />
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Set Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="password">
              New Password
            </label>
            <div className="relative flex items-center">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                <FaLock />
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prev) => ({ ...prev, password: '' }));
                }}
                placeholder="Enter new password"
                required
                className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.password ? 'border-red-500' : 'border-gray-300'}`}
              />
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors.password && (
              <p className="text-red-500 text-xs mt-1">{errors.password}</p>
            )}
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <div className="relative flex items-center">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                <FaLock />
              </span>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setErrors((prev) => ({ ...prev, confirmPassword: '' }));
                }}
                placeholder="Confirm your new password"
                required
                className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`}
              />
              <span
                onClick={() => setShowConfirmPassword((prev) => !prev)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors.confirmPassword && (
              <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>
            )}
          </div>

          <button
            type="submit"
            className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg w-full hover:bg-indigo-600 transition duration-300 focus:outline-none focus:shadow-outline"
          >
            Set Password
          </button>
        </form>
      </div>
    </div>
  );
};

export default setPassword;
