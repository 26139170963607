import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SignupVerificationPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate(); 

  const closeModal = () => {
    setIsOpen(false);
    navigate('/login'); 
  };

  const resendEmail = () => {
    
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              Email Verification Sent
            </h2>
            <p className="text-gray-600 mb-4">
              We have sent a verification email to your registered email address. 
              Please check your inbox and follow the instructions to verify your account.
            </p>
            {/* <p className="text-gray-600 mb-4">
              Didn't receive the email? You can{' '}
              <span
                className="text-blue-600 cursor-pointer hover:underline"
                onClick={resendEmail}
              >
                resend it
              </span>.
            </p> */}
            <button
              className="bg-indigo-600 text-white py-2 px-4 rounded-lg w-full"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SignupVerificationPopup;
