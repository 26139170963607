import React from "react";
import { FaSort } from "react-icons/fa";
import { getUserInfoFromToken } from "../utils/decryptToken";
// import "./blinking.css"; // Ensure you have your blinking CSS defined

const DataTable = ({
  headers = [],
  data = [],
  fieldKeys = [],
  actions = [],
}) => {
  const userInfo = getUserInfoFromToken();
  const { branchId: userBranchId, userId: tokenUserId } = userInfo;

  const isEndingSoon = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string") return false;

    const dateParts = dateStr.split("/");
    if (dateParts.length !== 3) return false;

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Adjust for zero-based months
    const year = parseInt(dateParts[2], 10);

    const date = new Date(year, month, day);
    const now = new Date();
    const diffTime = date - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 3 && diffDays >= 0; // Checks if it's within 3 days
  };

  const isOverdue = (status) => {
    return status === "Overdue" || status === "Upcoming";
  };

  const renderRow = (row, rowIndex) => {
    const shouldBlink = isEndingSoon(row.endDate) || isOverdue(row.status); // Check for both conditions

    return (
      <tr
        key={rowIndex}
        className={`${rowIndex % 2 === 0 ? "bg-gray-50" : "bg-white hover:bg-gray-100"}`}
      >
        {fieldKeys.map((field, index) => (
          <td
            key={index}
            className={`border border-gray-300 px-4 py-2  ${
              shouldBlink ? "blinking red-text" : "text-gray-600"
            }`}
          >
            {row[field]}
          </td>
        ))}
        {actions.length > 0 && (
          <td className="border border-gray-300 px-4 py-2">
            <div className="flex space-x-4">
              {actions.map((action, actionIndex) => (
                <span key={actionIndex} onClick={() => action.handler(row)}>
                  {typeof action.icon === "function" ? action.icon(row) : action.icon}
                </span>
              ))}
            </div>
          </td>
        )}
      </tr>
    );
  };

  if (!headers.length || !fieldKeys.length || !data.length) {
    return (
      <div className="overflow-x-auto bg-white shadow-lg rounded-lg p-4">
        <p className="text-center text-gray-500">No data available</p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto bg-white shadow-lg rounded-lg">
      <table className="min-w-full border-collapse border border-gray-300">
        <thead className="bg-gray-800 text-white">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider"
              >
                <div className="flex justify-between items-center">
                  {header}
                </div>
              </th>
            ))}
            {actions.length > 0 && (
              <th className="border border-gray-300 px-4 py-3 text-left text-sm font-semibold uppercase tracking-wider">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => renderRow(row, rowIndex))
          ) : (
            <tr>
              <td
                colSpan={headers.length + (actions.length > 0 ? 1 : 0)}
                className="border border-gray-300 px-4 py-2 text-center text-gray-500"
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
