import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./../pages/Dashboard";
import Users from "./../components/Users";
import ManageRoles from "./../components/ManageRoles";


import User from "../pages/User/User";
import { getUserInfoFromToken } from "../utils/decryptToken";
const AppRoutes = () => {

  const userInfo = getUserInfoFromToken();

  return (
    <Routes>   
      <Route path="/" element={<Dashboard />} />
      <Route path="/user" element={<Users />} />
      <Route path="/manageroles" element={<ManageRoles />} />

    </Routes>
  );
};

export default AppRoutes;
