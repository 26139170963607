import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";

const ManageRoles = () => {
  const [roles, setRoles] = useState([]);
  const [permissions] = useState([
    "create_user",
    "edit_user",
    "get_user",
    "deactivate_user",
    "create_students",
    "edit_students",
    "get_students",
  ]); // Example permissions
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);

  // Fetch roles on component mount
  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    // Replace with your API call
    const response = [
        {
          id: 1,
          name: "Admin",
          permissions: [
            "create_user",
            "edit_user",
            "get_user",
            "deactivate_user",
            "create_students",
            "edit_students",
            "get_students",
          ],
        },
        {
          id: 2,
          name: "Relationship Manager",
          permissions: [
            "create_user",
            "edit_user",
            "get_user",
            "create_students",
            "edit_students",
            "get_students",
          ], // Excludes deactivate_user
        },
        {
          id: 3,
          name: "Staff",
          permissions: [
            "get_user",
            "get_students",
          ], // Only view-related permissions
        },
      ];
      
    setRoles(response);
  };

  const handleSave = () => {
    // Handle saving role
    setModalOpen(false);
    fetchRoles(); // Refresh roles
  };

  const openModal = (role = null) => {
    setCurrentRole(role); // Set role for editing or null for creating
    setModalOpen(true);
  };

  const handleDelete = (roleId) => {
    // Handle delete logic
    
    fetchRoles();
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-semibold text-gray-800">Manage Roles</h1>
        <button
          onClick={() => openModal()}
          className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 shadow"
        >
          <FaPlus /> Add Role
        </button>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-200 text-gray-800">
              <th className="border px-4 py-2 text-left">Role Name</th>
              <th className="border px-4 py-2 text-left">Permissions</th>
              <th className="border px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((role) => (
              <tr
                key={role.id}
                className="hover:bg-gray-50 transition-all duration-200"
              >
                <td className="border px-4 py-2">{role.name}</td>
                <td className="border px-4 py-2 text-sm text-gray-600">
                  {role.permissions.join(", ")}
                </td>
                <td className="border px-4 py-2 text-center">
                  <div className="flex justify-center gap-3">
                    <button
                      onClick={() => openModal(role)}
                      className="bg-yellow-400 text-white p-2 rounded hover:bg-yellow-500"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(role.id)}
                      className="bg-red-500 text-white p-2 rounded hover:bg-red-600"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-lg">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              {currentRole ? "Edit Role" : "Add Role"}
            </h2>
            <input
              type="text"
              placeholder="Role Name"
              defaultValue={currentRole?.name || ""}
              className="w-full border border-gray-300 p-3 rounded mb-4 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
            <div>
              <h3 className="font-semibold mb-2 text-gray-800">Permissions:</h3>
              <div className="grid grid-cols-2 gap-3">
                {permissions.map((permission) => (
                  <div key={permission} className="flex items-center">
                    <input
                      type="checkbox"
                      id={permission}
                      defaultChecked={currentRole?.permissions?.includes(
                        permission
                      )}
                      className="h-4 w-4 text-blue-600"
                    />
                    <label
                      htmlFor={permission}
                      className="ml-2 text-gray-700 text-sm"
                    >
                      {permission}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageRoles;
