import React, { useEffect, useState } from 'react';
import axiosPrivateInstance from '../api/axiosPrivateInstance';
import DataTable from './DataTable';
import { FaEdit, FaTrash } from "react-icons/fa";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobile: '',
    password: '',
    roleId: '',
    isActive: true,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosPrivateInstance.get('/users/all');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const activeStatus = formData.isActive ? 1 : 0;

    const userData = {
      id: formData.id,
      fullName: formData.fullName,
      email: formData.email,
      mobile: formData.mobile,
      roleId: formData.roleId,
      isActive: activeStatus,
    };

    const userData2 = {
      createdBy: formData.id,
      fullName: formData.fullName,
      email: formData.email,
      mobile: formData.mobile,
      roleId: formData.roleId,
      isActive: activeStatus,
      password: formData.password,
    };

    try {
      if (isEditing) {
        await axiosPrivateInstance.put('/users/edit', userData);
      } else {
        await axiosPrivateInstance.post('/userss', userData2);
      }

      setFormData({ fullName: '', email: '', mobile: '', password: '', roleId: '', isActive: true });
      setIsEditing(false);
      setIsModalOpen(false);

      const response = await axiosPrivateInstance.get('/users/all');
      setUsers(response.data);
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleEdit = (user) => {
    setFormData(user);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDelete = async (userId) => {
    try {
      await axiosPrivateInstance.delete(`/soft-delete/${userId}`);
      const response = await axiosPrivateInstance.get('/users/all');
      setUsers(response.data);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleCloseModal = () => {
    setFormData({ fullName: '', email: '', mobile: '', password: '', roleId: '', isActive: true });  // Clear form data
    setIsModalOpen(false);  // Close the modal
    setIsEditing(false);    // Reset editing state
  };

  const headers = ['Full Name', 'Email', 'Mobile', 'Role'];
  const fieldKeys = ['fullName', 'email', 'mobile', 'roleId'];

  const actions = [
    {
      icon: <FaEdit className="text-indigo-500 text-xl cursor-pointer" />,
      handler: handleEdit,
    },
    {
      icon: <FaTrash className="text-red-500 text-xl cursor-pointer" />,
      handler: handleDelete,
    },
  ];

  const data = users.map(user => ({
    fullName: user.fullName,
    email: user.email,
    mobile: user.mobile,
    roleId: user.roleId == '1' ? 'Admin' : user.roleId == '2' ? 'Staff' : '',
    actions: '',
  }));

  return (
    <div className="max-w-8xl mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold">Manage Users</h2>

        {/* Button to open modal, positioned at the right */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center py-2 px-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition duration-300"
        >
          <span className="mr-2">+</span> Create User
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">{isEditing ? 'Edit User' : 'Create User'}</h3>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-600">Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-600">Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-600">Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-600">Role</label>
                <select
                  name="roleId"
                  value={formData.roleId}
                  onChange={handleChange}
                  required
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="">Select Role</option>
                  <option value="1">Admin</option>
                  <option value="2">Staff</option>
                </select>
              </div>

              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={handleCloseModal}  // Close and reset the modal
                  className="py-2 px-4 text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                >
                  {isEditing ? 'Update User' : 'Create User'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <DataTable
        headers={headers}
        data={data}
        fieldKeys={fieldKeys}
        actions={actions}
      />
    </div>
  );
};

export default Users;
