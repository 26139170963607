import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuItems: [],
  loading: false,
  error: null,
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuItems: (state, action) => {
      state.menuItems = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setMenuItems, setLoading, setError } = menuSlice.actions;

export default menuSlice.reducer;
