import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosPrivateInstance from "../../api/axiosPrivateInstance";

const EditStudent = ({ student, onClose, onSave }) => {
    const formatDate = (date) => {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`; 
    };

  const [editForm, setEditForm] = useState({
    id : student.id,
    matriculationNumber: student.matriculationNumber,
    name: student.name,
    email: student.email,
    phoneNumber: student.phoneNumber,
    dateOfBirth: formatDate(student.dateOfBirth), 
  });
  

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axiosPrivateInstance.put(
        "/student/update",
        editForm
      );

      if (response.data) {
        alert("Student updated successfully!");
        onClose();
        onSave();
      }
    } catch (error) {
      alert("Error updating student. Please try again later.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h2 className="text-2xl font-bold mb-4">Edit Student</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700">Matriculation Number</label>
            <input
              type="text"
              name="matriculationNumber"
              value={editForm.matriculationNumber}
              onChange={handleFormChange}
              className="w-full p-3 border rounded-md"
              disabled
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={editForm.name}
              onChange={handleFormChange}
              className="w-full p-3 border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={editForm.email}
              onChange={handleFormChange}
              className="w-full p-3 border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Phone</label>
            <input
              type="text"
              name="phoneNumber"
              value={editForm.phoneNumber}
              onChange={handleFormChange}
              className="w-full p-3 border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Date of Birth</label>
            <input
              type="date"
              name="dateOfBirth"
              value={editForm.dateOfBirth}
              onChange={handleFormChange}
              className="w-full p-3 border rounded-md"
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-400 text-white rounded-md"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSaveChanges}
              className="px-4 py-2 bg-indigo-500 text-white rounded-md"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditStudent;
