import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { Outlet } from 'react-router-dom';

const Layout = ({ isSidebarOpen, toggleSidebar }) => {
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Fixed Header */}
        <Header toggleSidebar={toggleSidebar} />

        {/* Scrollable Outlet */}
        <div className="flex-1 p-2 bg-gray-100 overflow-y-auto overflow-x-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
