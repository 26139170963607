import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const verificationErrorPage = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

   
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const success = params.get("success");
    const message = params.get("message");

    return { success, message };
  };

  useEffect(() => {
    const { success, message } = getQueryParams();
    setSuccess(success === "true");  
    setMessage(message);
  }, [location.search]);

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        {success ? (
          <h2 style={styles.success}>Success!</h2>
        ) : (
          <h2 style={styles.error}>Failed to verify please contact admin.</h2>
        )}
        <p>{message}</p>
        
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  card: {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize:"10vh"
  },
  success: {
    color: "green",
  },
  error: {
    color: "red",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default verificationErrorPage;
