import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import {
  validateEmail,
  validateMobile,
  validatePassword,
} from "../utils/validations";
import { encryptText } from "../utils/AESencryption";
import { toast } from "react-toastify";
import { loginUser } from "../api/api";
import gusLogo from "../assets/logo.jpg";
import { getUserInfoFromToken } from "../utils/decryptToken";
const Login = () => {
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEmailLogin, setIsEmailLogin] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    

    if (token) {
      const newtoken = getUserInfoFromToken(token);
      if (
        newtoken.roleId == 2
      ) {
        navigate("/booking");
      } else {
        navigate("/user");
      }
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    const emailError = validateEmail(identifier);
    const mobileError = validateMobile(identifier);
    let encryptedIdentifier;

    if (isEmailLogin) {
      encryptedIdentifier = encryptText(identifier);
    } else {
      encryptedIdentifier = encryptText(identifier);
    }

    const encryptedPassword = encryptText(password);
    

    if (isEmailLogin && emailError) {
      newErrors.identifier = emailError;
    } else if (!isEmailLogin && mobileError) {
      newErrors.identifier = mobileError;
    }

    if (Object.keys(newErrors).length === 0) {
      loginUser(encryptedIdentifier, encryptedPassword)
        .then((result) => {
          if (result && !result.error) {
            
            const token = result.data.token;
            

            return new Promise((resolve, reject) => {
              

              try {
                localStorage.setItem("token", token);
                resolve(token);
              } catch (error) {
                reject("Error saving token to localStorage");
              }
            });
          } else {
            return Promise.reject("Login failed, no token returned");
          }
        })
        .then((token) => {
          
          return new Promise((resolve, reject) => {
            
            const newToken = getUserInfoFromToken(token);
            if (newToken) {
              resolve(newToken);
            } else {
              reject("Error extracting user info from token");
            }
          });
        })
        .then((newToken) => {
          

          

          if (newToken) {
            if (
              newToken.roleId == 2
            ) {
              navigate("/booking");
            } else {
              navigate("/user");
            }
          }
        })
        .catch((error) => {
          console.error("Error in the login process:", error);
        });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full border border-gray-200">
        <img src={gusLogo} alt="Logo" className="w-20 mx-auto mb-4" />
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">
          Login
        </h2>

        <div className="flex justify-center mb-4">
          <button
            type="button"
            onClick={() => setIsEmailLogin(true)}
            className={`py-2 px-4 ${
              isEmailLogin
                ? "bg-indigo-600 text-white"
                : "text-blue-600 border border-blue-600"
            } rounded-l-lg transition duration-300`}
          >
            Login with Email
          </button>
          <button
            type="button"
            onClick={() => setIsEmailLogin(false)}
            className={`py-2 px-4 ${
              !isEmailLogin
                ? "bg-indigo-600 text-white"
                : "text-blue-600 border border-blue-600"
            } rounded-r-lg transition duration-300`}
          >
            Login with Mobile
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-medium mb-1"
              htmlFor="identifier"
            >
              {isEmailLogin ? "Email" : "Mobile Number"}
            </label>
            <div className="relative flex items-center">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                <FaUser />
              </span>
              <input
                type="text"
                id="identifier"
                value={identifier}
                onChange={(e) => {
                  setIdentifier(e.target.value);
                  setErrors((prev) => ({ ...prev, identifier: "" }));
                }}
                placeholder={
                  isEmailLogin ? "Enter your email" : "Enter your mobile number"
                }
                required
                className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.identifier ? "border-red-500" : "border-gray-300"
                }`}
              />
            </div>
            {errors.identifier && (
              <p className="text-red-500 text-xs mt-1">{errors.identifier}</p>
            )}
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-medium mb-1"
              htmlFor="password"
            >
              Password
            </label>
            <div className="relative flex items-center">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                <FaLock />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors((prev) => ({ ...prev, password: "" }));
                }}
                placeholder="Enter your password"
                required
                className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-10 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                  errors.password ? "border-red-500" : "border-gray-300"
                }`}
              />
              <span
                onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {errors.password && (
              <p className="text-red-500 text-xs mt-1">{errors.password}</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg w-full hover:bg-indigo-600 transition duration-300 focus:outline-none focus:shadow-outline"
          >
            Login
          </button>
        </form>
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{" "}
            <a href="/signup" className="text-blue-600 hover:underline">
              Register here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
