import React, { useEffect, useState } from 'react';
import AddUniversity from './AddUniversity';
import EditUniversity from './EditUniversity';
import { fetchUniversities } from './UniversityServices';
import DataTable from '../../components/DataTable';
import { FaEdit, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import axios from 'axios';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';

const University = () => {
  const [universities, setUniversities] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);

  useEffect(() => {
    const getUniversities = async () => {
      const data = await fetchUniversities();
      setUniversities(data);
    };
    getUniversities();
  }, []);

  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const handleUniversityAdded = (newUniversity) => {
    setUniversities([...universities, newUniversity]);
  };

  const handleUniversityUpdated = (updatedUniversity) => {
    setUniversities((prevUniversities) =>
      prevUniversities.map((university) =>
        university.id === updatedUniversity.id ? updatedUniversity : university
      )
    );
  };

  const handleDeactivate = async (university) => {
    const newStatus = university.isActive.data === 1 ? 0 : 1; // Toggle status
    try {
      const updatedUniversity = {
        universityId: university.id,
        universityName: university.name,
        status: newStatus,
      };
      const response = await axiosPrivateInstance.put(
        '/university/update',
        updatedUniversity
      );

      if (response) {
        setUniversities((prevUniversities) =>
          prevUniversities.map((u) =>
            u.id === university.id
              ? { ...u, isActive: { data: newStatus } }
              : u
          )
        );
      }
    } catch (err) {
      console.error("Error toggling university status:", err);
    }
  };

  const headers = ['ID', 'Name'];
  const fieldKeys = ['id', 'name'];

  const actions = [
    {
      icon: (row) =>
        row.isActive.data == 1 ? (
          <FaToggleOn className="text-green-500 text-xl cursor-pointer" />
        ) : (
          <FaToggleOff className="text-red-500 text-xl cursor-pointer" />
        ),
      handler: (row) => handleDeactivate(row),
    },
    {
      icon: <FaEdit className="text-indigo-500 text-xl cursor-pointer" />,
      handler: (row) => {
        setSelectedUniversity(row);
        setShowEditModal(true);
      },
    },
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Universities</h1>
        <button
          onClick={toggleAddModal}
          className="flex items-center py-2 px-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition duration-300"
        >
          <span className="mr-2">+</span> Add University
        </button>
      </div>

      <DataTable
        headers={headers}
        data={universities}
        fieldKeys={fieldKeys}
        actions={actions}
      />

      {showAddModal && (
        <AddUniversity
          onClose={toggleAddModal}
          onUniversityAdded={handleUniversityAdded}
        />
      )}

      {showEditModal && selectedUniversity && (
        <EditUniversity
          university={selectedUniversity}
          onClose={toggleEditModal}
          onUniversityUpdated={handleUniversityUpdated}
        />
      )}
    </div>
  );
};

export default University;
