import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import DataTable from "../../components/DataTable";
import EditStudent from "./EditStudent.jsx";
import axiosPrivateInstance from "../../api/axiosPrivateInstance.js";
import { getUserInfoFromToken } from "../../utils/decryptToken.js";

const StudentPage = () => {
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [loadingUniversities, setLoadingUniversities] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [error, setError] = useState(null);
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const newtoken = getUserInfoFromToken(token);
      if (newtoken.roleId === 2) {
        setSelectedUniversity(newtoken.universityId);
        setIsDropdownDisabled(true);
      }
    }

    const fetchUniversities = async () => {
      setLoadingUniversities(true);
      setError(null);
      try {
        const response = await axiosPrivateInstance.get("/university/get");
        if (response.data && response.data.length > 0) {
          setUniversities(response.data);
        } else {
          setError("No universities found.");
        }
      } catch (err) {
        setError("Error fetching universities. Please try again later.");
      } finally {
        setLoadingUniversities(false);
      }
    };

    fetchUniversities();
  }, []);

  useEffect(() => {
    if (!selectedUniversity) return;

    const fetchStudents = async () => {
      setLoadingStudents(true);
      setError(null);
      try {
        const response = await axiosPrivateInstance.post("/student/get", {
          universityId: selectedUniversity,
        });

        if (response.data && response.data.length > 0) {
          const formattedStudents = response.data.map((student) => ({
            ...student,
            dateOfBirth: formatDate(student.dateOfBirth),
          }));
          setStudents(formattedStudents);
        } else {
          setError("No students found for the selected university.");
        }
      } catch (err) {
        setError("Error fetching students. Please try again later.");
      } finally {
        setLoadingStudents(false);
      }
    };

    fetchStudents();
  }, [selectedUniversity]);

  const handleUniversityChange = (e) => {
    const universityId = e.target.value;
    setSelectedUniversity(universityId);
    setStudents([]); // Clear students when university is changed
  };

  const handleEditClick = (student) => {
    setSelectedStudent(student);
  };

  const headers = [
    "Matriculation Number",
    "Name",
    "Email",
    "Phone",
    "Date of Birth",
  ];
  const fieldKeys = [
    "matriculationNumber",
    "name",
    "email",
    "phoneNumber",
    "dateOfBirth",
  ];

  const actions = [
    {
      icon: <FaEdit className="text-indigo-600 text-xl" />,
      handler: (row) => handleEditClick(row),
    },
  ];

  return (
    <div className="container mx-auto p-6 bg-gray-50 shadow-sm rounded-lg">
      {loadingUniversities && (
        <p className="text-center text-gray-500">Loading universities...</p>
      )}

      {!isDropdownDisabled && (
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Select University
          </label>
          <select
            value={selectedUniversity}
            disabled={isDropdownDisabled}
            onChange={handleUniversityChange}
            className="w-full sm:w-1/3 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value="">Select University</option>
            {universities.length > 0 ? (
              universities.map((university) => (
                <option key={university.id} value={university.id}>
                  {university.name}
                </option>
              ))
            ) : (
              <option value="">No universities available</option>
            )}
          </select>
        </div>
      )}

      {loadingStudents && (
        <p className="text-center text-gray-500">Loading students...</p>
      )}

      {students.length > 0 ? (
        <DataTable
          headers={headers}
          fieldKeys={fieldKeys}
          data={students}
          actions={actions}
        />
      ) : (
        selectedUniversity &&
        !loadingStudents && (
          <p className="text-center text-gray-500 mt-4">
            No students found for the university.
          </p>
        )
      )}

      {/* Edit Modal */}
      {selectedStudent && (
        <EditStudent
          student={selectedStudent}
          onClose={() => setSelectedStudent(null)}
          onSave={() =>
            handleUniversityChange({ target: { value: selectedUniversity } })
          }
        />
      )}
    </div>
  );
};

export default StudentPage;
