import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import Layout from './components/Layout';
import AppRoutes from './Routes/AppRoutes'; 
import Login from './pages/Login'; 
import PrivateRoute from './Routes/PrivateRoutes'; 
import Signup from './pages/Signup';
import VerificationErrorPage from "./components/verificationErrorPage"
import ForgotPassword from './pages/sendEmailVerification';
import ResetPassword from "./pages/resetPassword"
import SetPassword from "./pages/setPassword"
import CsvUploader from './pages/UploadCSV/Uploadcsv';
import StudentPage from './pages/UploadCSV/Student';
import University from './pages/University/University';
import BookingDetails from './pages/Booking/Booking';

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev); 
  };

  return (
    <Router>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick={true} pauseOnHover={true} draggable={true} /> 
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/institute" element={<Institute/>} /> */}
        <Route element={<PrivateRoute element={<Layout isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />} />}>
        {/* <Route path ="/user-permissions" element={<Sidebar/>} /> */}

          <Route path="/*" element={<AppRoutes />} />
          <Route path="/csv" element={<CsvUploader/>}/>
          <Route path="/student" element={<StudentPage/>}/>
          <Route path="/universities" element={<University/>}/>
          <Route path="/booking" element={<BookingDetails/>}/>

        </Route>
        <Route path="/signup" element={<Signup />} />
        <Route path="/verificationErrorPage" element={<VerificationErrorPage />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />
        <Route path="/setPassword" element={<SetPassword/>} />

      </Routes>
    </Router>
  );
};

export default App;
