import React, { useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { validateEmail } from '../utils/validations';
import { toast } from 'react-toastify';
import { resetPassword, sendResetPasswordLink } from '../api/api';  

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);

    if (emailError) {
      setErrors({ email: emailError });
    } else {
      try {
        await sendResetPasswordLink(email);
        
        
        setEmail('');
      } catch (error) {
        console.error(error.message || 'Failed to send reset link.');
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full border border-gray-200">
        <img src={imsLogo} alt="Logo" className="w-32 mx-auto mb-4" />
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Forgot Password</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="email">
              Email
            </label>
            <div className="relative flex items-center">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                <FaUser />
              </span>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prev) => ({ ...prev, email: '' }));
                }}
                placeholder="Enter your email"
                required
                className={`shadow-md appearance-none border rounded-lg w-full py-2 pl-10 pr-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
              />
            </div>
            {errors.email && (
              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
            )}
          </div>

          <button
            type="submit"
            className="bg-indigo-600 text-white font-bold py-2 px-4 rounded-lg w-full hover:bg-indigo-600 transition duration-300 focus:outline-none focus:shadow-outline"
          >
            Send Reset Link
          </button>
        </form>

        <div className="mt-4 text-center">
          <p className="text-sm text-gray-600">
            Remember your password? <a href="/login" className="text-blue-600 hover:underline">Login here</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
